<template>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div
    class="pswp"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <!-- Background of PhotoSwipe.
      It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
          PhotoSwipe keeps only 3 of them in the DOM to save memory.
          Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <button
            v-if="showCloseButton"
            class="pswp__button pswp__button--close"
            title="閉じる (Esc)"
          ></button>
          <!-- ボタンクリック時に背景にあるボタンが反応してしまうため非表示 -->
          <!--
          <button
            class="pswp__button pswp__button--share"
            title="シェア"
          ></button>

          <button
            class="pswp__button pswp__button--fs"
            title="全画面表示"
          ></button>

          <button
            class="pswp__button pswp__button--zoom"
            title="ズームイン/アウト"
          ></button>
          -->

          <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>

        <button
          class="pswp__button pswp__button--arrow--left"
          title="前へ"
        ></button>

        <button
          class="pswp__button pswp__button--arrow--right"
          title="次へ"
        ></button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import UI from 'photoswipe/dist/photoswipe-ui-default'

const DEFAULT_OPTIONS = {
  closeOnScroll: false,
  clickToCloseNonZoomable: false,
  shareEl: false,
  errorMsg:
    '<div class="pswp__error-msg"><a href="%url%" target="_blank">画像</a>の読み込みに失敗しました</div>'
}

export default {
  name: 'Gallery',
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      photoswipe: null,
      loaded: false,
      loadedItems: this.items
    }
  },
  computed: {
    showCloseButton() {
      const browser = this.$ua.browser()
      if (browser === 'Internet Explorer') {
        return true
      }
      return false
    }
  },
  mounted() {
    this.loadedItems.forEach((item, index) => {
      this.loadedItems[index].w = 230
      this.loadedItems[index].h = 190
    })
  },
  methods: {
    async open(index, params = {}) {
      if (!this.loadedItems.length) return
      // 画像Sizeの取得は初回のみにする
      if (!this.loaded) {
        await Promise.all(
          this.loadedItems.map(async (item, index) => {
            const image = await this.loadImage(item.src)
            // retina対応のため表示Sizeは、取得Size/2
            this.loadedItems[index].w = image.width / 2
            this.loadedItems[index].h = image.height / 2
          })
        )
        this.loaded = true
      }
      let options = Object.assign({ index }, DEFAULT_OPTIONS, params)
      this.photoswipe = new PhotoSwipe(this.$el, UI, this.loadedItems, options)
      document.body.appendChild(this.$el)
      this.photoswipe.init()
      this.photoswipe.listen('close', () => {
        this?.$el?.parentNode?.removeChild(this.$el)
      })
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = err => reject(err)
        img.src = src
      })
    },
    close() {
      this.photoswipe.close()
    }
  }
}
</script>
<style src="~/node_modules/photoswipe/dist/photoswipe.css"></style>
<style src="~/node_modules/photoswipe/dist/default-skin/default-skin.css"></style>
<style lang="scss">
.pswp {
  z-index: z(gallery) !important;
  &__caption__center {
    text-align: center;
  }

  .pswp__bg {
    background: rgb(0, 0, 0);
  }

  &--touch {
    .pswp {
      &__button {
        &--arrow {
          &--left,
          &--right {
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
